<template>
	<div id="home" class="container has-text-centered">
		<h1 class="title is-2" style="font-weight: 800;">
			Looking to signup for something?
		</h1>
		<p>
			Checkout all the challenges, and services, that we have to offer on
			<a @click="redirect()">stacywig.com</a>
		</p>
		<a
			@click="redirect()"
			class="button is-primary shadow has-text-weight-bold mt-5"
			>Take me there!</a
		>
	</div>
</template>

<script>
export default {
	methods: {
		redirect() {
			window.location = 'https://stacywig.com?ref=register';
		}
	}
};
</script>
